import React from "react"
import { graphql, Link } from "gatsby"
import moment from "moment"
import PropTypes from "prop-types"

import Layout from "src/components/Layout"
import PageHero from "src/components/PageHero"
import Content from "src/components/Content"
import RelatedInsights from "src/components/insights/Related"
import { getExcerpt } from "src/utils/posts"

import logoMark from "src/assets/images/vaporware-logo-mark.svg"
import "./postTemplate.scss"

const PostTemplate = ({data: {page: {
  html, frontmatter: {
  meta, image, imageCredit, author, categories, tags,
}}}}) => {
  
  const excerpt = getExcerpt({text: html, characeters: 160})
  const newMeta = { 
    ...meta,
    description: meta.description || excerpt,
    image: meta.image || {childImageSharp: {gatsbyImageData: { src:"/img/card-default-post.png"}}},
  }

  return (
    <Layout meta={newMeta}>
      <div className="post-template">
        {image ?
          <PageHero
            title={meta.title}
            description={`Posted ${moment(meta.date).format("LL")} by ${author} in ${categories.join(", ")}`}
            className="post"
            image={image}
            imageCredit={imageCredit}
          />
          :
          <PageHero
            title={meta.title}
            description={`Posted ${moment(meta.date).format("LL")} by ${author} in ${categories.join(", ")}`}
            className="post"
            heroTriangles
            overlay={false}
          />
        }
        <article className="post-body">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <Content html={html} />
              </div>
            </div>
          </div>
        </article>

        <div className="post-mark">
          <Link to="/insights">
            <img src={logoMark} alt="Vaporware" />
          </Link>
        </div>

        <section className="post-resources">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <h2 className="h1 text-center">Related Insights</h2>
              </div>
            </div>
            <RelatedInsights categories={categories} tags={tags} currentId={meta.id} />
          </div>
        </section>
      </div>
    </Layout>
  )
}

PostTemplate.defaultProps = {
  data: {
    page: {
      frontmatter: {
        meta: {},
        image: {},
        imageCredit: "",
        author: "",
        categories: [],
        tags: [],
      },
      html: "",
    },
  },
}

PostTemplate.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      frontmatter: PropTypes.shape({
        meta: PropTypes.object,
        image: PropTypes.object,
        imageCredit: PropTypes.shape({
          name: PropTypes.string,
          link: PropTypes.string,
        }),
        author: PropTypes.string,
        categories: PropTypes.arrayOf(PropTypes.string),
        tags: PropTypes.arrayOf(PropTypes.string),
      }),
      html: PropTypes.string,
    }),
  }),
}

export default PostTemplate

export const pageQuery = graphql`query ($path: String!) {
  page: markdownRemark(frontmatter: {meta: {path: {eq: $path}}}) {
    html
    ...MetaFragment
    frontmatter {
      title
      image {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      categories
      tags
      author
      imageCredit {
        name
        link
      }
    }
  }
}
`

import React from "react"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"

import InsightCarousel from "./Carousel"

const RelatedInsights = (props) => (
  <StaticQuery
    query={graphql`
      query AllPublishedInsights {
        allInsights: allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___meta___date] }
          filter: {
            frontmatter: {
              meta: { 
                template: { in: ["post","letter"] }
                published: { eq: true }
                indexed: { ne: false }
              }
            }
          }
        ) {
          cards: edges {
            node {
              ...InsightFragment
            }
          }
        }
      }
    `}

    render={data => {
      const { categories, currentId, tags } = props
      const { cards } = data.allInsights

      const relatedCategories = categories.concat(tags)

      const otherInsights = cards.filter((card) => (
        card.node.frontmatter.meta.id !== currentId
      ))

      const hasCategory = otherInsights.filter((card) => (
        card.node.frontmatter.categories !== null
      ))

      const atleastOneCategory = hasCategory.filter((card) => (
        card.node.frontmatter.categories.filter(value => relatedCategories.includes(value)).length
      ))

      const maxCards = atleastOneCategory.splice(0, 10)

      return (
        <InsightCarousel cards={maxCards} />
      )

    }}
  />
)

RelatedInsights.defaultProps = {
  categories: [],
  tags: [],
  currentId: "",
}

RelatedInsights.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  tags: PropTypes.arrayOf(PropTypes.string),
  currentId: PropTypes.string,
}

export default RelatedInsights
